import { SHARED_MODEL, api, helper } from ":mods";
import { ENDPOINTS } from "../const";
import { contactMock } from "../mocks";

const mock = false;
export async function postContactUs(body) {
  return api
    .post(ENDPOINTS.CONTACT_US, body)
    .then(async (res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {});
}

export async function getCountries() {
  if (helper.env.isDev && mock) return contactMock(true);
  return api
    .get<SHARED_MODEL.ApiResponseSchema<{ data: { name: string }[] }[]>>(ENDPOINTS.CONTACT_COUNTRIES)
    .then(async (res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
